export default [
  {
    path: "/product", //产品与解决方案goodsPage
    component: () => import("@/views/product/productPage.vue"),
    meta: {
      title: "麦默通 - 产品与解决方案",
      footTitle: "如果您需要了解产品和解决方案的更多信息",
    },
  },
  {
    path: "/goodsPage", //产品与解决方案
    component: () => import("@/views/product/goods/goodsPage.vue"),
    meta: {
      title: "麦默通 - 产品与解决方案",
      footTitle: "如果您需要了解产品和解决方案的更多信息",
    },
  },
  {
    path: "/goodsdetail", //手柄想详情
    component: () => import("@/views/product/goodsDetail/goodsDetail.vue"),
    meta: {
      title: "麦默通 - 产品与解决方案",
      footTitle: "如果您需要了解产品和解决方案的更多信息",
    },
  },
  {
    path: "/productdetail", //产品详情
    component: () => import("@/views/product/productDetail/productDetail.vue"),
    meta: {
      title: "麦默通 - 产品与解决方案",
      footTitle: "如果您需要了解产品和解决方案的更多信息",
    },
  },
];
